import {createTheme} from '@mui/material/styles';

export const ORBTheme = createTheme({

	typography: {
		fontFamily: ["Roboto"]
	},
	palette: {
		background: {
			default: '#F8F8F8',
			paper: '#FFFFFF'
		},
		primary: {
			main: '#000000'
		},
		secondary: {
			main: '#f50057'
		}
	}
})