import React from 'react';
import {styled} from '@mui/material/styles';

const theme = React.useTheme;

const Panel = styled('div')(({theme}) => `
	background-color: ${theme.palette.background.paper};
	border-radius: 25px;
	padding: 3%;
	box-shadow: 5px 5px 2px grey;
	margin-bottom: 2%;
`);

const PagePanel = styled('div') (({theme}) => `
	text-align: center;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
`);

export {Panel, PagePanel};