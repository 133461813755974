
class PasswordValidator {

	static validate(pass) {

		let digits = /[0-9]+/;
		let chars = /[a-z]+/;
		let upperChars = /[A-Z]/;
		if (pass.match(digits) && pass.match(chars) && pass.match(upperChars) && pass.length > 4) {
			return true;
		}
		else {
			return false;
		}
	}
}

export default PasswordValidator;