import React, {useState} from 'react';
import ORBLogo from '../assets/images/logo.png';
import {useSearchParams, useNavigate} from 'react-router-dom';
import { Alert, Button, Collapse, TextField, Box, Typography, Divider, List, ListItem } from '@mui/material';
import AuthAPI from '../services/AuthAPI';
import {PagePanel, Panel} from '../components/Panels';
import PasswordValidator from '../services/PasswordValidator';

export default function ForgotPasswordUpdate({activate_account}) {

	let [searchParams, setSearchParams] = useSearchParams();
	let navigate = useNavigate();
	let [processing, setProcessing] = useState(false);
	let [password, setPassword] = useState("");
	let [passwordConfirmation, setPasswordConfirmation] = useState("");
	let [confirmationError, setConfirmationError] = useState(null);
	let [passwordError, setPasswordError] = useState(null);
	let [allClear, setAllClear] = useState(false);
	let [msg, setMsg] = useState({view: false, severity: "success", text: ""});

	let doUpdatePassword = (e) => {
		e.preventDefault();
		if (!allClear) return;
		let token = searchParams.get("token");
		if (!token) {
			setMsg({view: true, severity: "error", text: "Token de sessão inválido"});
			return
		}
		setProcessing(true);
		AuthAPI.updateForgottenPass(token, password)
		.then(() => {
			setMsg({view: true, severity: "success", text: activate_account ? "Conta ativada com sucesso.": "Senha alterada com sucesso."});
			setTimeout(() => {navigate("/login")},3000);
		})
		.catch(() => {
			setMsg({view: true, severity: "error", text: activate_account ? "Falha ao ativar conta." : "Falha ao atualizar senha!"});
			setProcessing(false);
		});
	}

	let setPasswords = (passwd, passwd_confirm) => {
		setAllClear(true);
		setPassword(passwd);
		setPasswordConfirmation(passwd_confirm);
		validatePass(passwd);
		if (passwd !== passwd_confirm) {
			setConfirmationError(true);
			setAllClear(false);
		} else {
			setConfirmationError(false);
		}
	}

	let validatePass = (pass) => {
		if (PasswordValidator.validate(pass)) {
			setPasswordError(false);
		}
		else {
			setPasswordError(true);
			setAllClear(false);
		}
	}

	const pagePanelStyle = {
		width: {xs: '100%', lg: '50%'}
	};

	const listItemStyle = {
		fontSize: '9pt',
		fontStyle: 'italic',
		marginBottom: '0.5em',
		padding: 0
	};
	
	const listStyle = {
		marginLeft: '50%',
		transform: 'translate(-50%, 0)'
	};

	const textStyle = {
		width:{xs:'80%', lg:'25%'}, 
		marginRight: {lg:'5%'}
	};

	const buttonStyle = {
		marginTop: '22px'
	};

	return (
			<PagePanel sx={pagePanelStyle}>
				<Box component="img" src={ORBLogo} alt="ORB Music Logo" sx={{marginTop: '2%', marginBottom: '2%'}}/><br/>
				<Panel>
					{ activate_account ?  <Typography variant="h5">Ativação da conta ORB</Typography>
					: <Typography variant="h5">Nova Senha</Typography>
					}
					<Divider sx={{marginBottom: '2%'}}/>
					{ activate_account ?  <Typography variant="title1"sx={{fontStyle: 'italic'}}>Crie a sua senha para ativar sua conta.</Typography>
					: <Typography variant="title1"sx={{fontStyle: 'italic'}}>Configure uma nova senha de acordo com os padrões de segurança.</Typography>
					}
					<List sx={listStyle}>
						<ListItem sx={listItemStyle}>Letras maiúsculas, minúsculas e números</ListItem>
						<ListItem sx={listItemStyle}>5 ou mais caracteres</ListItem>
					</List>
					<Collapse in={msg.view}>
						<Alert severity={msg.severity} onClose={ () => {setMsg({view:false, text: ""});}}>{msg.text}</Alert>
					</Collapse>
					<form onSubmit={doUpdatePassword}>
						<TextField id="password" name="password" required label="Senha" variant="outlined" type="password" margin="normal" 
						sx={textStyle} value={password} onChange={(e) => {setPasswords(e.target.value, passwordConfirmation);}} error={passwordError}/>
						<TextField id="confirm_password" name="confirm_password" required label="Confirmação Senha" variant="outlined" type="password" margin="normal" 
						sx={textStyle} value={passwordConfirmation} onChange={(e) => {setPasswords(password, e.target.value);}} error={confirmationError}/>
						<Button sx={buttonStyle} id="send" disabled={processing? true: null} variant="contained" color="secondary" size="large" type="submit">{activate_account ? "CONFIRMAR" : "ATUALIZAR"}</Button>
					</form>
				</Panel>
			</PagePanel>
	)
}