import React from 'react';
import ReactDOM from 'react-dom/client';
import ORBRouter from './router/ORBRouter';
import {ORBTheme} from './themes/ORBTheme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={ORBTheme}>
      <CssBaseline/>
      <div className="app-container">
        <ORBRouter />
      </div>
    </ThemeProvider>
);
