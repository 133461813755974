import axios from 'axios';
import config from '../config';

axios.defaults.baseURL = config.APIURL;

export default class AuthAPI {

	static _build_auth_header(token) {
		return {"x-orb-auth": token};
	}

	static async getSession(token) {
		return axios.get("private/sessions/me", {headers: AuthAPI._build_auth_header(token)})
		.then((response) => { return response.data;});
	}

	static async doLogout(token) {
		return axios.delete("private/sessions/me", {headers: AuthAPI._build_auth_header(token)})
		.then((response) => {return response.data})
	}

	static async doLogin(username, password) {
		return axios.post("public/sessions", {"username": username, "password": password})
		.then((response) => { return response.data;});
	}

	static async sendForgotPassEmail(username) {
		return axios.post("public/sessions/otp", {"username": username})
		.then((response) => { return response.data});
	}

	static async updateForgottenPass(token, password) {
		return axios.put(`public/credentials/${token}`, {"new_password": password})
		.then((response) => {return response.data;});
	}

	static async changePassword(token, currentPassword, newPassword) {
		return axios.post("private/credentials/me", {"current_password": currentPassword,  "new_password": newPassword},
						{headers: AuthAPI._build_auth_header(token)})
		.then((response) => { return response.data;})
	}
}