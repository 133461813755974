import React, { useState, useEffect } from 'react';
import ORBLogo from '../assets/images/logo.png';
import { Box, Alert, Button, Collapse, TextField, Typography } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import AuthAPI from '../services/AuthAPI';
import AuthContext from '../contexts/AuthContext';
import {PagePanel, Panel} from '../components/Panels';

export default function Login() {

	let navigate = useNavigate();
	let {search} = useLocation();
	let [viewAlert, setViewAlert] = useState(false);
	let [username, setUsername] = useState("");
	let [password, setPassword] = useState("");
	let [enableSubmit, setEnableSubmit] = useState(true);
	let authInfo = React.useContext(AuthContext);
	let [validatingSession, setValidatingSession] = useState(true);

	let prepareSession = () => {
		let authToken = localStorage.getItem("orb_auth_token");
		if (!authToken) {
			setValidatingSession(false);
		} else {
			authInfo.buildSession(authToken)
			.then((success) => {
				if (success){
					navigate(`/${search}`)
				} else {
					setValidatingSession(false);
				}
			});
		}
	}
	let doLogin = (e) => {
		e.preventDefault();
		setEnableSubmit(false);
		AuthAPI.doLogin(username, password)
		.then((user) => {
			localStorage.orb_auth_token = user.session_token;
			prepareSession();
		})
		.catch((e) => {setViewAlert(true);})
		.finally((e) => {setEnableSubmit(true)})
		
	};

	useEffect( prepareSession, [validatingSession] );

	const loginStyle = {
	};
	const pagePanelStyle = {
		width: {xs: '100%', lg:'20%'},
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	};
	const controlStyle = {
		marginBottom: '2em',
		width: '100%'
	};
	return (
		<PagePanel sx={pagePanelStyle}>
			{validatingSession ?
				<Typography sx={{fontStyle: 'italic'}}>Buscando sessão ativa ...</Typography>
			:
			
				<Panel sx={loginStyle}>
					<Box component="img" src={ORBLogo} alt="ORB Music Logo" sx={{marginTop: '2%', marginBottom: '2%'}}/>
					<Collapse in={viewAlert}>
						<Alert severity="error" onClose={ () => {setViewAlert(false);}}>Usuário /senha inválidos!</Alert>
					</Collapse>
					<form onSubmit={doLogin}>
						<TextField id="username" name="username" required label="Usuário" variant="outlined" type="email" 
						sx={controlStyle} value={username} onChange={(e) => {setUsername(e.target.value);}}/>
						<TextField  id="password" name="password" required label="Senha" variant="outlined"  type="password"
						sx={controlStyle} value={password} onChange={(e) => {setPassword(e.target.value);}}/>
						<Button id="login" disabled={enableSubmit? null: true} variant="contained" color="secondary" size="large"
						type="submit" sx={{width: '90%', marginBottom: '2%'}}> ENTRAR</Button>
						<Link to="/forgot_password">Esqueceu a senha?</Link>
					</form>
				</Panel>
		}
		</PagePanel>
	)
}