import React, {useState, useEffect} from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import ORBLogo from '../assets/images/logo.png';
import AuthContext from '../contexts/AuthContext';
import {PagePanel} from '../components/Panels';
import {Box, Link, Typography} from '@mui/material';

export default function Session() {

	let [message, setMessage] = useState("Validando autenticação")
	let [searchParams, setSearchParams] = useSearchParams();
	let navigate = useNavigate();
	let {search} = useLocation();
	let authInfo = React.useContext(AuthContext);

	const doAuthorization = (user) => {
		let appParam = searchParams.get("app")
		if (!appParam) {
			doRedirect("/profile"); // if there is no app to redirect, show profile page
			return;
		}
		setMessage("Validando autorização")
		let appToSend = "";
		if (user.apps.length > 0) { // Verify if user is authorized to use the application

			for (let i = 0; i < user.apps.length; i++) {
				if (appParam === user.apps[i].id) {
					appToSend = user.apps[i].url + user.apps[i].callback_url;
					break;
				}
			}
		}
		if ( appToSend === "") {
			setMessage("Usuário não possui autorização para acessar esta aplicação.")
		} else {
			window.location.replace(`${appToSend}?auth_token=${authInfo.auth_token}`);
		}
	};

	const doRedirect = (url) => {
		navigate(`${url}${search}`);
	}
	useEffect(()=>{
		doAuthorization(authInfo.user);
	},[])

	const pagePanelStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	};

	return (
			<PagePanel sx={pagePanelStyle}>
				<Box component="img" alt="ORB Music Logo" src={ORBLogo}/>
				<Typography sx={{fontStyle: 'italic'}}>{message}</Typography>
				<Link sx={{marginTop: '2em'}} onClick={() => {doRedirect("/logout");}} underline='none' href="#" color="#007bff">Sair</Link>

			</PagePanel>
	)
}