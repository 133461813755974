import React, {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import { Alert, Box, Button, Collapse, Divider, Grid, List, ListItem, Link, TextField, Typography} from '@mui/material';
import AuthContext from '../contexts/AuthContext';
import {PagePanel, Panel} from '../components/Panels';
import ORBLogo from '../assets/images/logo.png';
import PasswordValidator from '../services/PasswordValidator';
import AuthAPI from '../services/AuthAPI';

const Profile = () => {

	let emptyCredential = {newPass: "", confirmPass: "", currentPass: ""};
	let navigate = useNavigate();
	let {search} = useLocation();
	let {user, auth_token} = React.useContext(AuthContext);
	let [newCredential, setNewCredential] = useState(emptyCredential);
	let [passError, setPassError] = useState(false);
	let [confirmError, setConfirmError] = useState(false);
	let [processing, setProcessing] = useState(false);
	let [msg, setMsg] = useState({view: false, severity: "success", text: ""});

	const doLogoff = () => {
		navigate(`/logout${search}`)
	};

	const doChangePassword = (e) => {
		e.preventDefault();
		if (passError || confirmError) return;
		setProcessing(true);
		AuthAPI.changePassword(auth_token, newCredential.currentPass, newCredential.newPass)
		.then(() => { 
			setNewCredential(emptyCredential);
			setMsg({view: true, severity: "success", text: "Senha alterada com sucesso"});
		})
		.catch(() => { setMsg({view: true, severity: "error", text: "Falha ao atualizar senha!"})})
		.finally(() => { 
			setProcessing(false);
			setTimeout(()=> {setMsg({view: false, severity: "success", text:""})},3000);
		});
	};

	const onPasswordFill = (credential) => {
		setPassError(!PasswordValidator.validate(credential.newPass));
		setConfirmError(credential.newPass !== credential.confirmPass);
		setNewCredential(credential);
	}

	const listStyle = {
		width: {xs:'100%', lg:'48%'},
		paddingTop: '20px',
		display: 'inline-block'
	};
	const listItemStyle = {
		fontSize: '9pt',
		fontStyle: 'italic',
		marginBottom: '0.5em',
		padding: 0
	};
	return (
	<PagePanel sx={{width: {xs:'100%', lg: '50%'}, }}>
		<Box component="img" src={ORBLogo} alt="ORB Music Logo" sx={{marginTop: '2%', marginBottom: '2%'}}/><br/>
		<Panel>
			<Typography variant="h3">Perfil</Typography>
			<Divider sx={{marginBottom: '2%'}}/>
			<TextField fullWidth margin="normal" id="name" name="name" required label="Nome" variant="outlined" value={user.name} />
			<TextField fullWidth margin="normal" id="email" name="email" required label="Email" variant="outlined" value={user.email} />
			<Typography variant="subtitle1">Sistemas disponíveis:</Typography>
			<Grid container spacing={2}>
				{user.apps.map((app, index) => (
					<Grid key={index} item xs={12} lg={4}><Link href={app.url}>{app.name}</Link></Grid>
				))}
			</Grid>
		</Panel>
		<Panel>
			<Typography variant="h4">Alterar Senha</Typography>
			<Divider sx={{marginBottom: '2%'}}/>
			<form onSubmit={doChangePassword}>
				<TextField margin="normal" id="newPass" name="newPass" required label="Nova Senha" variant="outlined" type="password" error={passError} value={newCredential.newPass}
					sx={{width:{xs:'100%', lg:'48%'}, marginRight: {lg: '2%'}}} onChange={(e) => {onPasswordFill({...newCredential, newPass: e.target.value});}}/>
				<TextField margin="normal" id="confirmPass" name="confirmPass" required label="Confirmação Senha" variant="outlined" type="password" error={confirmError}  value={newCredential.confirmPass}
					sx={{width:{xs:'100%', lg:'48%'}}} onChange={(e) => {onPasswordFill({...newCredential, confirmPass: e.target.value});}}/>
				<TextField margin="normal" id="currentPass" name="currentPass" required label="Senha Atual" variant="outlined" type="password"  value={newCredential.currentPass}
					sx={{width:{xs:'100%', lg:'48%'}, marginRight: {lg: '2%'}}} onChange={(e) => {onPasswordFill({...newCredential, currentPass: e.target.value});}}/>
				<List sx={listStyle}>
					<ListItem sx={listItemStyle}>Letras maiúsculas, minúsculas e números</ListItem>
					<ListItem sx={listItemStyle}>5 ou mais caracteres</ListItem>
				</List>
				<Button id="changePass" variant="contained" color="secondary" size="large" type="submit" disabled={processing}
					sx={{width:{ xs:'50%', lg:'25%'}}}>ALTERAR</Button>
				<Collapse sx={{marginTop: '10px'}} in={msg.view}>
					<Alert severity={msg.severity} onClose={ () => {setMsg({view:false, text: ""});}}>{msg.text}</Alert>
				</Collapse>
			</form>
		</Panel>
		<Button id="logout" variant="contained" color="secondary" size="large" onClick={doLogoff}>SAIR</Button>
	</PagePanel>)
}

export default Profile;