import React from 'react';
import {BrowserRouter, Routes, Route, Navigate, useLocation} from 'react-router-dom';
// import { Link, useNavigate, useLocation } from 'react-router-dom';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import Session from '../pages/Session';
import ForgotPassword from '../pages/ForgotPassword';
import ForgotPasswordUpdate from '../pages/ForgotPasswordUpdate';
import Profile from '../pages/Profile';
import AuthContext, {AuthProvider} from '../contexts/AuthContext'

const PrivateRoute = ({children}) => {

	let {search} = useLocation();
	let auth = React.useContext(AuthContext);
	return auth.user ? children : <Navigate to={`/login${search}`} />
};

const ORBRouter = () => {


	return (
		<AuthProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<PrivateRoute><Session /></PrivateRoute>} />
					<Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
					<Route path="/login" element={<Login />}/>
					<Route path="/forgot_password" element={<ForgotPassword />} />
					<Route path="/forgot_password/update" element={<ForgotPasswordUpdate />} />
					<Route path="/account_activation" element={<ForgotPasswordUpdate activate_account="true"/>} />
					<Route path="/logout" element={<Logout />} />
				</Routes>	
			</BrowserRouter>
		</AuthProvider>
	)
}

export default ORBRouter;