import React , {createContext, useState} from 'react';
import AuthAPI from '../services/AuthAPI';


const AuthContext = createContext();

const AuthProvider = ({children}) => {

	const [user, setUser] = useState(null);
	const [token, setToken] = useState(null);
	const buildSession = async (token) => {
		return AuthAPI.getSession(token)
		.then((user) => {
			setUser(user);
			setToken(token);
			return true;
		})
		.catch(() => {return false;})
	};

	return (
		<AuthContext.Provider value={{user, auth_token: token, buildSession}}>{children}</AuthContext.Provider>
	)
}

export default AuthContext;
export {AuthProvider};