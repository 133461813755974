import {useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import AuthAPI from '../services/AuthAPI';

export default function Logout() {

	let navigate = useNavigate();
	let {search} = useLocation();

	useEffect(() => {

		let token = localStorage.getItem("orb_auth_token");
		AuthAPI.doLogout(token)
		localStorage.removeItem("orb_auth_token");
		navigate(`/login${search}`);
	}, []);



}