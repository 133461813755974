import React , {useState} from 'react';
import ORBLogo from '../assets/images/logo.png';
import { Alert, Button, Collapse, TextField, Box, Typography, Divider } from '@mui/material';
import AuthAPI from '../services/AuthAPI';
import {PagePanel, Panel} from '../components/Panels';
import { isElementOfType } from 'react-dom/test-utils';

export default function ForgotPassword() {

	let emptyMsg = {view: false, severity: "success", text: ""};
	let [email, setEmail] = useState("");
	let [sending, setSending] = useState(false);
	let [msg, setMsg] = useState(emptyMsg);

	let sendPassLink = (e) => {
		e.preventDefault();
		setSending(true);
		setMsg(emptyMsg);
		AuthAPI.sendForgotPassEmail(email)
		.then(() => {showMessage("success", "Email enviado com sucesso.")})
		.catch(() => {showMessage("error", "Falha ao enviar email!")})
		.finally(() => {setSending(false);})
	};

	let showMessage = (severity, text) => {
		setMsg({view: true, severity, text});
		setTimeout(()=> {setMsg(emptyMsg);}, 5000);
	}
	const pagePanelStyle = {
		width: {xs: '100%', lg: '50%'}
	}
	return (
			<PagePanel sx={pagePanelStyle}>
				<Box component="img" src={ORBLogo} alt="ORB Music Logo" sx={{marginTop: '2%', marginBottom: '2%'}}/><br/>
				<Panel>
					<Typography variant="h5">Esqueceu a senha?</Typography>
					<Divider sx={{marginBottom: '2%'}}/>
					<Typography variant="title1">Digite o seu email abaixo que lhe enviaremos um link para configurar novamente.</Typography>
					<Collapse in={msg.view}>
						<Alert severity={msg.severity} onClose={ () => {setMsg({view:false, severity: "success", text: ""});}}>{msg.text}</Alert>
					</Collapse>
					<form onSubmit={sendPassLink}>
						<TextField id="email" name="email" required label="Email" variant="outlined" type="email" margin="normal"
						sx={{width: {xs:'90%', lg:'50%'}, marginLeft: {xs: '5%', lg:'25%'}, marginRight: {xs: '5%', lg:'25%'}}} value={email} onChange={(e) => {setEmail(e.target.value);}}/>
						<Button id="send" disabled={sending? true: null} variant="contained" color="secondary" size="large"  type="submit">ENVIAR</Button>
					</form>
				</Panel>
			</PagePanel>
	);
}